<template>
  <Section v-loading="loading" id="multiple-barcode-view">
    <div v-if="showBarcodes">
      <div class="inner-navbar">
        <el-form
          :inline="true"
          class="barcode-form-inline d-flex align-items-center"
        >
          <el-form-item label="No.of Copies">
            <el-input
              class="number-counter"
              type="number"
              min="1"
              max="100"
              placeholder="Enter no.of copies"
              v-model="qrcodeCopies"
              @change="generateQrcodeCopies()"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :disabled="disablePrint"
              @click="onPrint"
              v-if="showPrint"
              :title="printButtonTitle"
              type="button"
              class="print-btn"
            >
              <span class="el-icon-printer"></span>Print Qrcodes
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner-content">
        <div class id="barcodesBody">
          <div
            v-for="(caseId, index) in generatedCaseIds"
            :key="index"
            style="margin-top: 20px"
            class="each-barcode"
          >
            <div class="barcode">
              <div class="show-barcode">
                <el-row>
                  <el-col :span="24">
                    <div class="barcode-body" id="barcodeBody">
                      <div class="bar-code-table">
                        <div
                          class="bar-code-table-inner"
                          style="padding: 5px 10px"
                        >
                          <table
                            style="width: 100%; padding: 1em; max-width: 550px"
                          >
                            <tbody>
                              <tr style="vertical-align: baseline">
                                <th
                                  class="patient-name-block"
                                  style="
                                    text-align: left;
                                    text-transform: uppercase;
                                  "
                                >
                                  <div
                                    class="patient-name"
                                    v-if="
                                      caseInformationMap[caseId].patient_info
                                    "
                                  >
                                    {{
                                      caseInformationMap[caseId].patient_info
                                        .first_name
                                    }}
                                    {{
                                      caseInformationMap[caseId].patient_info
                                        .middle_name
                                        ? caseInformationMap[caseId]
                                            .patient_info.middle_name
                                        : ""
                                    }}
                                  </div>
                                  <div
                                    class="patient-name"
                                    v-if="
                                      caseInformationMap[caseId].patient_info
                                    "
                                  >
                                    {{
                                      caseInformationMap[caseId].patient_info
                                        .last_name
                                    }}
                                  </div>
                                </th>
                                <th class="date-column">
                                  <div class="dates">
                                    <p
                                      class="doc-dates"
                                      style="
                                        margin: 0;
                                        text-align: right;
                                        font-weight: 500;
                                      "
                                    >
                                      DOC:
                                      {{
                                        momentWithTimezone(
                                          caseInformationMap[caseId]
                                            .collection_date,
                                          "MM-DD-YYYY"
                                        )
                                      }}
                                    </p>
                                    <p
                                      class="dob-date"
                                      style="
                                        margin: 0;
                                        text-align: right;
                                        font-weight: 500;
                                      "
                                    >
                                      <span
                                        v-if="
                                          caseInformationMap[caseId]
                                            .patient_info
                                        "
                                      >
                                        DOB:
                                        {{
                                          dateWithMomentUTC(
                                            caseInformationMap[caseId]
                                              .patient_info.date_of_birth
                                          )
                                        }}
                                      </span>
                                    </p>
                                  </div>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <div class="content">
                            <el-row>
                              <el-col :span="24">
                                <div class="image">
                                  <qrcode-vue
                                    :value="
                                      caseInformationMap[caseId].accession_id
                                    "
                                    size="75"
                                    level="L"
                                    v-if="
                                      caseInformationMap[caseId].accession_id
                                    "
                                  />
                                </div>
                                <div
                                  class="assession-id"
                                  style="
                                    text-align: center;
                                    font-size: 11.953325px !important;
                                    font-weight: 600;
                                    letter-spacing: 0.15pt;
                                    line-height: 1;
                                  "
                                >
                                  {{ caseInformationMap[caseId].accession_id }}
                                </div>
                                <div class="case-types">
                                  <b>{{ trimmedCaseDetails(caseId) }}</b>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-barcode"
      v-if="
        !generatedCaseIds.length ||
        (!loading &&
          (!getMultipleBarcodeSources || !getMultipleBarcodeSources.length))
      "
    >
      <p>Unable to generate qrcodes.</p>
    </div>
  </Section>
</template>

<script>
import { mapGetters } from "vuex";
import caseDataHelper from "@/mixins/caseDataHelper";
import QrcodeVue from "qrcode.vue";
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import moment from "moment-timezone";
export default {
  mixins: [caseDataHelper],
  computed: {
    ...mapGetters("cases", [
      "getMultipleBarcodeSources",
      "getMultipleBarcodeStatus",
      "getCaseInformation",
    ]),
    ...mapGetters("profile", ["getProfileData"]),
    caseInformationMap() {
      if (
        !this.getMultipleBarcodeSources ||
        !this.getMultipleBarcodeSources.length
      ) {
        return {};
      }

      const data = {};

      this.getMultipleBarcodeSources.forEach((el) => {
        data[el.case_id] = el.case_information;
      });
      return data;
    },
    barCodeUrlsMap() {
      if (
        !this.getMultipleBarcodeSources ||
        !this.getMultipleBarcodeSources.length
      ) {
        return {};
      }

      const data = {};

      this.getMultipleBarcodeSources.forEach((el) => {
        data[el.case_id] = el.barcode_source;
      });

      return data;
    },
    printButtonTitle() {
      return this.disablePrint ? "Wait until images are ready" : "";
    },
    trimmedPatientName() {
      return (caseId) => {
        let caseData = this.caseInformationMap[caseId];
        if (caseData) {
          if (caseData.patient_info.middle_name) {
            let name =
              caseData.patient_info.first_name +
              " " +
              caseData.patient_info.middle_name +
              " ";
            caseData.patient_info.last_name;

            return name.slice(0, 13);
          } else {
            let name =
              caseData.patient_info.first_name +
              " " +
              caseData.patient_info.last_name;

            return name.slice(0, 13);
          }
        }
        return "";
      };
    },
    trimmedDoctorName() {
      return (caseId) => {
        let caseData = this.caseInformationMap[caseId];
        if (caseData) {
          let name = caseData.ordering_physician.name;
          return name.slice(0, 13);
        }
        return "";
      };
    },
    trimmedCaseDetails() {
      return (caseId) => {
        let caseName = this.caseTypesText(
          this.caseInformationMap[caseId].case_types
        );
        return caseName ? caseName.slice(0, 35) : "";
      };
    },
  },
  components: {
    QrcodeVue,
  },
  async mounted() {
    await this.generateBarcodes();
  },
  data() {
    return {
      showBarcodes: false,
      showPrint: false,
      disablePrint: false,
      loading: false,
      caseIds: [],
      generatedCaseIds: [],
      barcodeCopies: 1,
    };
  },

  methods: {
    async generateBarcodes() {
      this.loading = true;
      let params = {};
      try {
        if (this.$route.query.case_ids) {
          const caseIds = this.$route.query.case_ids;
          if (caseIds) {
            params.case_ids = [];
            if (typeof caseIds == "string") {
              params.case_ids.push(caseIds);
            } else {
              params.case_ids = caseIds;
            }
          } else {
            this.loading = false;
            return;
          }
          // params.case_ids.push(caseIds);
        } else if (this.$route.query.from_date && this.$route.query.to_date) {
          params.from_received_date = moment
            .tz(this.getProfileData.lab.timezone)
            .set(this.getLabTimeZoneData(this.$route.query.from_date))
            .startOf("day")
            .utc()
            .format();

          params.to_received_date = moment
            .tz(this.getProfileData.lab.timezone)
            .set(this.getLabTimeZoneData(this.$route.query.to_date))
            .endOf("day")
            .utc()
            .format();
        }
        params.key = "QR_CODES";
        await this.$store.dispatch("cases/generateMultipleBarcodes", params);

        if (this.getMultipleBarcodeStatus) {
          this.getMultipleBarcodeSources.forEach((el) => {
            this.generatedCaseIds.push(el.case_id);
          });

          this.showBarcodes = true;
          this.showPrint = true;
          this.disablePrint = true;

          setTimeout(() => {
            this.disablePrint = false;
          }, 2000);
        } else {
          this.$notify({
            type: "Error",
            title: "Failed",
            message: "Failed to generate multiple barcodes",
          });
        }
        this.caseInfo = JSON.parse(JSON.stringify(this.getCaseInformation));
        // eslint-disable-next-line no-empty
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
    async onPrint() {
      // await this.$htmlToPaper("barcodesBody");
      window.print();
    },
    generateBarcodeCopies() {
      this.showBarcodes = false;
      if (this.getMultipleBarcodeStatus) {
        this.generatedCaseIds = [];
        for (let i = 1; i <= this.barcodeCopies; i++) {
          this.getMultipleBarcodeSources.forEach((el) => {
            this.generatedCaseIds.push(el.case_id);
          });
        }
      } else {
        this.$notify({
          type: "Error",
          title: "Failed",
          message: "Failed to generate multiple barcodes",
        });
      }
      this.showBarcodes = true;
    },
  },
  unmounted() {
    this.$store.dispatch("cases/resetGenerateMultipleBarcodes");
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
#multiple-barcode-view {
  padding: 2em 0;
  font-family: "Open Sans", sans-serif;
  @page {
    size: A4;
    margin: 0 auto;
    width: 288px;
    // margin: 0 auto;
    height: 192px;
  }
  .number-counter {
    .el-input__inner {
      padding-right: 0 !important;
      font-weight: 500;
      color: #000;
      font-size: 15px;
    }
  }
  .each-barcode {
    margin-top: 0 !important;
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  .barcode-form-inline {
    .el-form-item {
      margin-bottom: 0;
    }
    margin-bottom: 45px;
  }
  #barcodeBody {
    .bar-code-table {
      width: 288px;
      margin: 4.85em auto;
      height: 192px;
      overflow: hidden;
      padding: 0;
      transform: rotate(90deg);
      table {
        padding: 10px 5px 0 10px !important;
      }
      th {
        font-size: 14px;
      }
      .designation {
        margin-top: 0;
      }
      .dates {
        p {
          line-height: 20px;
          font-size: 13px;
        }
      }
      .image {
        img {
          max-width: 200px;
          margin: 0 auto;
        }
        &.diasorin {
          img {
            max-width: 180px;
          }
        }
      }
    }
  }
  .inner-navbar {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 10px;
    .print-btn {
      background: #6f52ed;
      padding: 9px 15px;
      color: #fff;
      font-size: 1em;
      span {
        padding-right: 5px;
      }
    }
  }
  .inner-content {
    .bar-code-table {
      background: #fff;
      padding: 10px;
      .image {
        display: table;
        margin: 0 auto;
        max-width: 100% !important;
      }
      .case-types {
        text-align: center;
        b {
          font-size: 15px;
        }
      }
      table {
        margin: 0 auto;
      }
    }
  }
  .each-barcode {
    display: table;
    margin: 20px auto;
    margin-bottom: 20px;
  }
  @media print {
    .each-barcode {
      margin: 0 !important;
      display: block !important;
    }
    .inner-navbar {
      display: none !important;
      margin-top: 0 !important;
      margin-right: 0 !important;
    }
  }

  // @media print {
  #barcodeBody .bar-code-table {
    page-break-before: always;
    margin-bottom: 45px !important;
    margin-left: -48px !important;
    margin-right: 0 !important;
    background: #fff !important;
    margin-top: 48px !important;
    display: flex;
    align-items: flex-end;
  }
  .designation {
    margin-top: 0 !important;
    font-size: 8px !important;
  }
  #barcodeBody .bar-code-table .image img {
    // max-width: 100%;
    // height: 50px;
    margin-bottom: -5px;
  }
  #barcodeBody .bar-code-table #assession-id {
    text-align: center;
    font-size: 7pt !important;
    font-weight: 500;
    letter-spacing: 0.15pt;
    line-height: 1;
    padding-bottom: 0;
    margin-bottom: -20px;
  }
  #barcodeBody .bar-code-table th {
    font-size: 7px !important;
    font-weight: normal;
    letter-spacing: 0.1px;
  }
  #barcodeBody .bar-code-table .patient-name-block {
    padding-top: 8px;
  }
  #barcodeBody .bar-code-table .date-column {
    width: 95px;
    padding-top: 8px;
  }
  #barcodeBody .bar-code-table .patient-name {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    letter-spacing: 0;
  }
  #barcodeBody .bar-code-table .dates {
    font-size: 8pt;
    font-weight: 700;
    padding-right: 15px;
  }
  #barcodeBody .bar-code-table .dates p {
    line-height: 12px;
    font-size: 10px !important;
    width: 95px;
    font-weight: 800;
    letter-spacing: 0.2px;
  }
  .inner-content .bar-code-table .case-types b {
    font-size: 11px !important;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 1;
    padding-top: 0;
    margin: -10px 0 0 !important;
  }
  .bar-code-table-inner {
    width: 100%;
    padding: 10px 10px 6px 30px !important;
  }
  .patient-name-block {
    padding-left: 15px;
  }
  padding-top: 0 !important;

  #multiple-barcode-view {
    padding-top: 0;
  }
}
.no-barcode {
  text-align: center;
  font-size: 1.1em;
}
.bar-code-table-inner {
  padding-bottom: 10px !important;
}
#multiple-barcode-view .inner-content .bar-code-table .case-types {
  line-height: 10px;
}
</style>
